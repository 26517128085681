export default class Facebook {
	
	constructor() {
		this.facebookCookieExpire = (new Date());
		this.facebookCookieExpire.setTime((new Date()).getTime() + 3600000*24*180);
		this._setTriggerConditions();
		this._initTag();
	}
	
	showFacebookModal() {
		var modal = $('#fbModal');
		if(modal.length) {
			$('#fbModal').modal();
		} 
	}
	
	_setTriggerConditions() {
		this.pts = Util.getCookie('quantor_pts');
		this.threshold = Util.getCookie('quantor_threshold');
	    
	    if(typeof this.threshold === 'undefined') {
	    	this.threshold = 3;
	    	document.cookie = "quantor_threshold=" + this.threshold + ";path=/;expires=" + this.facebookCookieExpire.toGMTString();
	    }

	    this.pts = typeof this.pts === 'undefined' ? 1 : parseInt(this.pts) + 1;
	    document.cookie = "quantor_pts=" + this.pts + ";path=/;expires=" + this.facebookCookieExpire.toGMTString();
	    
	    if(parseInt(this.pts) > parseInt(this.threshold)) {
	        setTimeout(this._facebookCookieUpdate, 3000);
	        setTimeout(this.showFacebookModal, 3000);        
	    }
	}
	
	_initTag() {
	    $('#fb').css({height:"160px"});
	    $('#fb').mouseover( function () {   
	    	$('#fb').velocity("stop");
	    	$('#fb').css({height:"320px"}); 
	    	$("#fb").velocity({
    				right: "-10px",
    			},
    			{
    				duration: 200, 
    				easing: "linear"
    		});
	    });
	    $('#fb').mouseout( function () { 
	    	$('#fb').velocity("stop");
	    	$("#fb").velocity({
	    			right: "-253px",
	    		},
	    		{
	    			duration: 200, 
	    			easing: "linear",
	    	    	complete: function () {
	    	    		$('#fb').css({height:"160px"});
	    	    	}
	    	}); 
	    });
	}
	
	_facebookCookieUpdate() {
	    document.cookie = "quantor_pts=0;path=/;expires=" + facebook.facebookCookieExpire.toGMTString();
	    document.cookie = "quantor_threshold=" + (facebook.threshold * 2) + ";path=/;expires=" + facebook.facebookCookieExpire.toGMTString();
	}
	
}