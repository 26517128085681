import './module.public.layout.css';
import {onCLS, onINP, onLCP} from 'web-vitals/attribution';

function sendToGoogleAnalytics({name, delta, value, id, attribution, rating}) {
    // Assumes the global `gtag()` function exists, see:
    // https://developers.google.com/analytics/devguides/collection/ga4
    const eventParams = {
        // Built-in params:
        value: delta, // Use `delta` so the value can be summed.
        // Custom params:
        metric_id: id, // Needed to aggregate events.
        metric_value: value, // Optional.
        metric_delta: delta, // Optional.

        // OPTIONAL: any additional params or debug info here.
        // See: https://web.dev/articles/debug-performance-in-the-field
        metric_rating: rating,
        // debug_info: '...',
        // ...
    };

    switch (name) {
        case 'CLS':
            eventParams.debug_target = attribution.largestShiftTarget;
            break;
        case 'INP':
            eventParams.debug_target = attribution.interactionTarget;
            eventParams.debug_type = attribution.interactionType;
            eventParams.debug_time = attribution.interactionTime;
            eventParams.debug_load_state = attribution.loadState;
            eventParams.debug_interaction_delay = Math.round(attribution.inputDelay);
            eventParams.debug_processing_duration = Math.round(attribution.processingDuration);
            eventParams.debug_presentation_delay =  Math.round(attribution.presentationDelay);
            eventParams.debug_interaction_type =  Math.round(attribution.interactionType);
            break;
        case 'LCP':
            eventParams.debug_target = attribution.element;
            break;
    }

    gtag('event', name, eventParams);
}

require('../../lib/v3/bootstrap-toggle/js/bootstrap-toggle.min.js');
require('../../lib/v3/bootstrap-select/1.13.2/js/bootstrap-select.min.js');
require('../../lib/app/node_modules/velocity-animate');

onCLS(sendToGoogleAnalytics);
onINP(sendToGoogleAnalytics);
onLCP(sendToGoogleAnalytics);

import Util from '../../js/app/common/util';
window.Util = Util;

import Navbar from '../../js/app/common/navbar';
window.Navbar = Navbar;

import Facebook from '../../js/app/facebook/facebook.js';
window.Facebook = Facebook;

$(document).ready(function() {
    window.navbar = new Navbar();
    window.facebook = new Facebook();
});