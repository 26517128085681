export default class Util {

	static getDistanceFromLatLonInMeters(lat1,lon1,lat2,lon2) {
	  var R = 6371*1000; // Radius of the earth in meters
	  var dLat = Util.deg2rad(lat2-lat1);  // deg2rad below
	  var dLon = Util.deg2rad(lon2-lon1); 
	  var a = 
	    Math.sin(dLat/2) * Math.sin(dLat/2) +
	    Math.cos(Util.deg2rad(lat1)) * Math.cos(Util.deg2rad(lat2)) * 
	    Math.sin(dLon/2) * Math.sin(dLon/2);
	  
	  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
	  var d = R * c; // Distance in meters
	  return d;
	}

	static deg2rad(deg) {
	  return deg * (Math.PI/180)
	}
	
	static calculateNationalAmount(foreignAmount, rate, unitCount) {
		return foreignAmount * (rate / unitCount);
	}
	
	
	static calculateForeignAmount(nationalAmount, rate, unitCount) {
		return nationalAmount / (rate / unitCount);
	}
	
	
	static buildOpinionBadge(data) {
		Util._buildOpinionBadge(data, '#cantorOpinion', '#noOpinion', '#positiveOpinion', '#negativeOpinion');
	}
	
	static buildRankingOpinionBadge(data) {
		var noOpinion = '#noOpinionRanking' + data.cantorid,
		positiveOpinion = '#positiveOpinionRanking' + data.cantorid,
		negativeOpinion = '#negativeOpinionRanking' + data.cantorid,
		cantorOpinion = '#cantorRankingRow' + data.cantorid + '> .cantorRankingColumnOpinion > .row > .cantor-opinion';
		
		Util._buildOpinionBadge(data, cantorOpinion, noOpinion, positiveOpinion, negativeOpinion);
	}
	
	static _buildOpinionBadge(data, cantorOpinion, noOpinion, positiveOpinion, negativeOpinion) {
		data.cantorposopinion = parseInt(data.cantorposopinion);
        data.cantornegopinion = parseInt(data.cantornegopinion);
        
        $(noOpinion)
    		.attr('href', 'kantor/'+ data.uname + '.html');
        $(positiveOpinion)
        	.attr('href', 'kantor/'+ data.uname + '.html')
        	.children('span').text(Math.round(data.cantorposopinion * 100 / (data.cantorposopinion + data.cantornegopinion)) + '%');
        $(negativeOpinion)
        	.attr('href', 'kantor/'+ data.uname + '.html')
        	.children('span').text(Math.round(data.cantornegopinion * 100 / (data.cantorposopinion + data.cantornegopinion)) + '%');
        
        if(data.cantorposopinion == 0 || data.cantorposopinion < data.cantornegopinion) {
            $(cantorOpinion).children('.positive').css('display', 'none'); 
        }
        if(data.cantornegopinion != 0 && data.cantorposopinion < data.cantornegopinion) {
            $(cantorOpinion).children('.negative').css('display', 'block'); 
        }
        if(data.cantorposopinion == 0 && data.cantornegopinion == 0) {
            $(cantorOpinion).children('.neutral').css('display', 'block'); 
        }
	}
	
	
	static buildTimeText(seconds, i18nSecondsAbb) {
		if(seconds > 90) {
            return  Math.ceil(seconds / 60) + 'min';
        }
        if(seconds > 60) {
            return '1min : ' + Math.ceil(seconds - 60) + i18nSecondsAbb;
        }
        return Math.ceil(seconds) + i18nSecondsAbb;
    }
	
	static setCookie(name, value, seconds) {
        var expire = new Date();
        expire.setTime(expire.getTime() + 1000*seconds);
        document.cookie = name + "=" + value + ";path=/;expires=" + expire.toGMTString();
    }
	
	static getCookie(c_name) {
		var i,x,y,ARRcookies=document.cookie.split(";");
		for (i=0;i<ARRcookies.length;i++){
			x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
			y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1)
			;x=x.replace(/^\s+|\s+$/g,"");
			if (x==c_name){
				return unescape(y);
			}
		}
		return null;
	}
	
	static countDecimals(value, separator) {
        if(Math.floor(value) === value) return 0;

        var splitted = value.toString().split(separator);

        if(splitted.length == 1) return 0;
        
        return splitted[1].length || 0;  
	}
	

}